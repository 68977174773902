import { CheckCircleOutlineSharp } from '@mui/icons-material';
import { Modal, ModalProps, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export interface LoadSpinnerProps extends Omit<ModalProps, 'children' | 'open'> {
  message: string;
  variant?: 'pageLoad' | 'asyncLoad';
  finished?: boolean;
  open?: boolean;
}

type VouchSpinnerProps = {
  color?: string;
} & React.SVGProps<SVGSVGElement>;

const VouchSpinner: React.FC<VouchSpinnerProps> = ({ color = '#3BE0AD', style, ...props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: color, ...style }}
    {...props}
  >
    <path
      d="M9.85872 9.84771C14.9884 4.71806 23.0448 4.30895 28.6465 8.55743L32.6747 4.52924C24.8386 -1.92216 13.226 -1.48158 5.862 5.85099C-1.50204 13.1836 -1.91115 24.8276 4.54025 32.6951L8.56844 28.6669C4.31996 23.0337 4.76054 14.9774 9.85872 9.84771Z"
      fill={color}
    />
    <path
      d="M30.157 30.146C25.2791 35.0239 17.7262 35.6533 12.156 32.0027L8.09631 36.0624C15.9324 41.8844 27.0729 41.255 34.1537 34.1427C41.2345 27.0304 41.8954 15.8899 36.0734 8.08533L32.0137 12.145C35.6643 17.7152 35.0349 25.2681 30.157 30.146Z"
      fill={color}
    />
  </svg>
);

export const Spinner = styled(VouchSpinner)(`
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}`);

const LoadSpinner = ({
  finished,
  message,
  open = true,
  variant = 'pageLoad',
}: PropsWithChildren<LoadSpinnerProps>) => {
  const theme = useTheme();
  const backdropProps: { invisible?: boolean; sx?: SxProps<Theme>; transitionDuration?: number } =
    {};
  let backgroundColor = '';
  let textColor = theme.designTokens.backgroundContrastText;
  if (variant === 'pageLoad') {
    backdropProps.invisible = true;
    backdropProps.transitionDuration = 0;
    backgroundColor = theme.designTokens.colorBackground;
  }
  if (variant === 'asyncLoad') {
    textColor = theme.designTokens.colorWhite;
  }

  let spinnerComponent = (
    <Spinner
      id="load-spinner"
      color={theme.designTokens.colorPrimary}
      style={{
        animationName: 'spin',
        animationDuration: '4000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        height: '3em',
        width: '3em',
      }}
    />
  );

  if (variant === 'asyncLoad' && finished) {
    spinnerComponent = (
      <CheckCircleOutlineSharp
        htmlColor={theme.designTokens.colorPrimary}
        sx={{
          width: 40,
          height: 40,
          margin: 0,
          padding: 0,
        }}
      />
    );
  }

  let displayMessage: string | JSX.Element = message;
  if (displayMessage === undefined || displayMessage === '') {
    displayMessage = <br />;
  }
  return (
    <Modal
      open={open}
      closeAfterTransition={false}
      componentsProps={{
        backdrop: {
          ...backdropProps,
          sx: {
            color: textColor,
            backgroundColor,
          },
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: 1,
      }}
    >
      <>
        <div
          id="spinner-root"
          style={{
            margin: 20,
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {spinnerComponent}
        </div>
        <Typography
          sx={{
            color: textColor,
          }}
          variant="h4"
        >
          {displayMessage}
        </Typography>
      </>
    </Modal>
  );
};

export default LoadSpinner;
