import { Box, Container, Grid, Typography } from '@vouch/ui';
import { useChat } from '@vouch/third-party/zendesk/widget';
import LogoutButtonContainer from 'containers/SignOutButtonContainer/SignOutButtonContainer';
import { chatWidgetClicked, viewAccessDenied } from 'features/eventTracking/eventTracking.actions';
import { getSessionInfo } from 'features/session/sessionSlice';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { attachAdditionalChatFields } from '@vouch/third-party/zendesk';

const AccessDenied: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const tracking = (path = '', openViaLink = false) => {
    dispatch(chatWidgetClicked({ path, openViaLink }));
    attachAdditionalChatFields();
  };
  const { show: showChat } = useChat({ tracking });
  const { email } = useSelector(getSessionInfo);

  const [params] = useSearchParams();
  const authSource = params.get('authSource') || 'auth0';

  useEffect(() => {
    dispatch(
      viewAccessDenied({
        authSource,
      })
    );
  }, [authSource, dispatch]);

  return (
    <Container>
      <Grid
        alignItems="center"
        className="loading-page"
        container
        data-testid="crumbs-page"
        justifyContent="center"
        textAlign="center"
        minHeight="100vh"
      >
        <Grid item className="loading-title" sm={10} md={6}>
          <Typography variant="h4" mb={1}>
            Access Denied
          </Typography>

          <Typography variant="body1" mb={2}>
            Your current email{' '}
            {email ? (
              <Box component="span">
                (
                <Box component="span" fontWeight="bold">
                  {email}
                </Box>
                )
              </Box>
            ) : (
              ''
            )}{' '}
            might not be authorized to view this page.
          </Typography>

          <Typography variant="body1" mb={4}>
            {
              "Please verify that you're signed in with the correct email. If you are, kindly request an authorized team member to grant you access."
            }
          </Typography>

          <LogoutButtonContainer />

          <Typography variant="body1" mt={14}>
            {'Require further support?'}
          </Typography>

          <Typography
            variant="subtitle2"
            onClick={showChat}
            className="chat-link label"
            sx={(theme) => ({
              color: theme.designTokens.colorPrimary,
              textDecoration: 'underline',
              cursor: 'pointer',
            })}
          >
            Chat with us
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccessDenied;
