import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '2910a301-efbc-4a9e-a01b-43b87a6e4adf',
  clientToken: import.meta.env.VITE_FIREBIRD_DD_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'firebird',
  env: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_GIT_REVISION,
  sampleRate: 100,
  sessionReplaySampleRate: import.meta.env.VITE_ENV === 'production' ? 100 : 0,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

datadogRum.startSessionReplayRecording();
