import { useEffect } from 'react';
import { bootZendesk } from '.';

type ChatProps = {
  flags?: Record<string, boolean>;
  cb?: () => void;
  tracking: (path: string, openViaClick: boolean) => void;
  zendeskJwt?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: any;
};

export const useChat = ({ tracking, theme }: ChatProps) => {
  useEffect(() => {
    bootZendesk({
      tracking,
      theme,
    });
  });

  return {
    hide: () => {
      window.zE('messenger', 'close');
    },
    show: () => {
      const path = window.location.href.split('?')[0];
      tracking(path, true);

      window.zE('messenger', 'open');
    },
  };
};
