import chroma from 'chroma-js';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: (webWidget: string, eventOrProperty: string, parameters?: any) => void;
  }
}

const VOUCH_ICON_FILL_COLOR = '#244239';
const MAX_COLOR_ATTEMPTS = 25; // (25 = 5 seconds) prevent endless polling for widget if disabled
const isLight = (color: string) => chroma(color).luminance() > 0.5;
const hexToRgba = (hex: string, alpha: number) => chroma(hex).alpha(alpha).css();

export const bootZendesk = ({
  onload = () => {},
  tracking,
  theme,
}: {
  onload?: () => void;
  tracking: (path: string, openViaLink: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
}) => {
  if (document.getElementById('ze-snippet')) {
    return;
  }
  createScript(onload);
  attachAnalytics(tracking);
  applyColorOverrides(theme);
};

function createScript(onload: () => void) {
  window.onerror = (errorMsg) => {
    if (
      typeof errorMsg === 'string' &&
      errorMsg.match(/Method webWidget:on\.open does not exist/)
    ) {
      return true;
    }
    return false;
  };

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'ze-snippet';
  script.async = true;
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${
    import.meta.env.VITE_ZENDESK_MESSENGER_KEY
  }`;
  script.onload = onload;

  document.getElementsByTagName('head')[0].appendChild(script);
}

export const zendeskChatIconIframe = () =>
  document.querySelector('[title^="Button to launch messaging window"]') as HTMLIFrameElement;

export const zendeskChatButton = (iframe: HTMLIFrameElement) =>
  iframe && iframe.contentWindow?.document.querySelector('[aria-label="Open messaging window"]');

const zendeskChatMessageIFrame = () =>
  document.querySelector('[title="Messaging window"]') as HTMLIFrameElement;

const getMessageWindow = (iframe: HTMLIFrameElement) =>
  iframe &&
  iframe.contentWindow?.document.querySelector(
    'div[role="presentation"] > div[role="presentation"]'
  );

function attachAnalytics(tracking: (path: string, openViaLink: boolean) => void) {
  const interval = setInterval(() => {
    if (typeof document === 'undefined') {
      clearInterval(interval);
      return;
    }

    const iframe = zendeskChatIconIframe();

    if (iframe && iframe.contentWindow) {
      const chatButton = zendeskChatButton(iframe);

      // Analytics & Additional fields when clicking widget
      if (chatButton) {
        const path = window.location.href.split('?')[0];

        chatButton.addEventListener('click', () => {
          tracking(path, true);
        });

        clearInterval(interval);
      }
    }
  }, 500);
}

export const attachAdditionalChatFields = () => {
  // Zendesk admin > Object & Rules > Tickets > Fields
  const FIELD_IDS = {
    current_url_id: 34916291101723,
  };
  window.zE('messenger:set', 'conversationFields', [
    { id: FIELD_IDS.current_url_id, value: window.location.href },
  ]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applyColorOverrides = (theme: any) => {
  let count = 0;

  const interval = setInterval(() => {
    count++;

    if (typeof document === 'undefined' || !theme?.designTokens || count > MAX_COLOR_ATTEMPTS) {
      clearInterval(interval);
      return;
    }

    const iconIframe = zendeskChatIconIframe();
    const messageIframe = zendeskChatMessageIFrame();

    const primary = theme.designTokens.colorPrimary;
    const contrastTextColor = getTextColor(primary, theme);

    if (iconIframe?.contentWindow) {
      const chatButton = zendeskChatButton(iconIframe) as HTMLElement;

      if (chatButton) {
        colorIcon(chatButton, primary, contrastTextColor);

        if (messageIframe?.contentWindow) {
          colorChatWindow(messageIframe, theme);
        } else {
          colorChatWindowWhenOpened(chatButton, theme);
        }
      }

      clearInterval(interval);
    }
  }, 200);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const colorChatWindow = (iframe: HTMLIFrameElement, theme: any) => {
  const window = getMessageWindow(iframe) as HTMLElement;

  if (window) {
    const primary = theme.designTokens.colorPrimary;
    const primaryDarker = theme.designTokens.colorPrimaryDarker;
    const contrastTextColor = getTextColor(primary, theme);

    return (
      colorMessageHeader(window, primary, contrastTextColor) &&
      colorMessageInput(window, primaryDarker) &&
      colorSendMessageButton(window, primaryDarker) &&
      colorPromptButtons(window, primaryDarker)
    );
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const colorChatWindowWhenOpened = (chatButton: HTMLElement, theme: any) => {
  chatButton?.addEventListener('click', () => {
    const interval = setInterval(() => {
      const messageIframe = zendeskChatMessageIFrame();
      if (messageIframe?.contentWindow && colorChatWindow(messageIframe, theme)) {
        clearInterval(interval);
      }
    }, 300);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTextColor = (backgroundColor: string, theme: any) =>
  isLight(backgroundColor) ? VOUCH_ICON_FILL_COLOR : theme.designTokens.colorWhite;

const colorIcon = (chatButton: HTMLElement, backgroundColor: string, iconFillColor: string) => {
  if (!chatButton) return;
  const chatButtonIcons =
    (chatButton.querySelectorAll('svg path') as NodeListOf<HTMLElement>) || [];

  chatButton.style.setProperty('background-color', backgroundColor);

  chatButtonIcons.forEach((icon) => {
    icon.style.setProperty('fill', iconFillColor);
  });
};

const colorMessageInput = (window: HTMLElement, color: string) => {
  const messageInput = window.querySelector('textarea') as HTMLElement;
  if (!messageInput) return;

  messageInput.style.setProperty('box-shadow', `0px 0px 0px 0.125rem ${color}`);
  messageInput.style.setProperty('border', `1px solid ${color}`);

  messageInput.addEventListener('focus', () => {
    messageInput.style.setProperty('box-shadow', `0px 0px 0px 0.125rem ${color}`);
    messageInput.style.setProperty('border', `1px solid ${color}`);
  });

  messageInput.addEventListener('blur', () => {
    messageInput.style.removeProperty('box-shadow');
    messageInput.style.removeProperty('border');
  });
  return true;
};

const colorMessageHeader = (window: HTMLElement, color: string, textColor: string) => {
  const headerSection = window.querySelector('section') as HTMLElement;
  if (!headerSection) return;

  headerSection.style.setProperty('background-color', color);
  (headerSection.querySelector('h2') as HTMLElement).style.setProperty('color', textColor);
  return true;
};

const colorPromptButtons = (window: HTMLElement, color: string) => {
  const promptButtons = window.querySelectorAll('button') as NodeListOf<HTMLElement>;
  if (promptButtons.length < 2) return;

  promptButtons.forEach((button) => {
    button.style.setProperty('border-color', color);
    button.style.setProperty('color', color);

    button.addEventListener('mouseover', () => {
      button.style.setProperty('background-color', hexToRgba(color, 0.2));
    });

    button.addEventListener('mouseout', () => {
      button.style.setProperty('background-color', 'white');
    });
  });
  return true;
};

const colorSendMessageButton = (window: HTMLElement, color: string) => {
  const parentContainer = window.querySelector('textarea') as HTMLElement;
  if (!parentContainer) return;

  const parentObserver = new MutationObserver(() => {
    const messageButton = window.querySelector('[title^="Send message"]') as HTMLElement;

    if (messageButton) {
      const buttonSvg = messageButton.querySelector('svg path') as HTMLElement;
      buttonSvg.style.setProperty('fill', color);
    }
  });

  parentObserver.observe(parentContainer, { childList: true, subtree: true });
  return true;
};

export const authenticateZendeskUser = async ({
  zendeskJwt = null,
}: {
  zendeskJwt: string | null;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.zE('messenger', 'loginUser', (callback: any) => {
    callback(zendeskJwt);
  });
};

export default {
  bootZendesk,
  authenticateZendeskUser,
};
